
import { defineComponent, ref } from 'vue';
import { nextYearDataForClosing, getPlanning } from '@/API/closing';
export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const nextYearInfo = ref<any[]>([]);

    const planningList = ref<any[]>([]);
    const kufriList = ref<any[]>([]);
    const yearList = ref<any[]>([]);

    const planningName = ref<string>('');
    const nextYear = ref<string>('');
    const planningId = ref<string>('');

    const handleChangePlanningName = (value: string) => {
      yearList.value = [
        ...new Set(nextYearInfo.value.filter((item: any) => item.planningName === value).map((item: any) => item.year))
      ];
      nextYear.value = '';
      planningId.value = '';
    };

    const handleChangeNextYear = (value: string) => {
      kufriList.value = [
        ...new Set(
          nextYearInfo.value
            .filter((item: any) => item.planningName === planningName.value && item.year === value)
            .map((item: any) => {
              return {
                id: item.id,
                name: item.kufriMonth
              };
            })
        )
      ];
      planningId.value = '';
    };

    const handleConfirm = () => {
      if (planningId.value) {
        emit('confirm', planningId.value);
      }
    };

    const init = () => {
      Promise.all([getPlanning(props.makeId), nextYearDataForClosing(props.makeId)]).then(
        ([selectedId, res2]: any[]) => {
          if (selectedId === null) {
            planningName.value = '';
            nextYear.value = '';
            planningId.value = '';
          } else {
            const selectedData = res2.find((item: any) => item.id === selectedId);
            planningName.value = selectedData.planningName;
            nextYear.value = selectedData.year;
            planningId.value = selectedId;
          }
          nextYearInfo.value = res2;
          planningList.value = [
            ...new Set(res2.filter((item: any) => item.planningName).map((item: any) => item.planningName))
          ];
          yearList.value = [
            ...new Set(
              res2.filter((item: any) => item.planningName === planningName.value).map((item: any) => item.year)
            )
          ];
          kufriList.value = [
            ...new Set(
              res2
                .filter((item: any) => item.planningName === planningName.value && item.year === nextYear.value)
                .map((item: any) => {
                  return {
                    id: item.id,
                    name: item.kufriMonth
                  };
                })
            )
          ];
        }
      );
    };

    init();

    return {
      planningList,
      kufriList,
      yearList,
      planningName,
      nextYear,
      planningId,
      handleChangePlanningName,
      handleChangeNextYear,
      handleConfirm,
      init
    };
  }
});
