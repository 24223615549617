import { ref, watch, Ref } from 'vue'
type DataType = {
  [key: string]: unknown;
}
export default function (data: Ref<DataType[]>, checked: Ref<boolean>) {
  // 展开行
  const expandedRowKeys = ref<number[]>([]);
  const recursion = () => {
    const arr: number[] = [];
    const recur = (arg: any[]) =>
      arg.forEach(item => {
        arr.push(item.id);
        if (item.children) recur(item.children);
      });
    recur(data.value);
    return arr;
  };
  const onExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      // 设置展开窗Key，代表展开操作
      expandedRowKeys.value.push(record.id);
    } else {
      // 代表折叠操作
      if (expandedRowKeys.value.length) {
        expandedRowKeys.value = expandedRowKeys.value.filter((v: any) => {
          return v !== record.id;
        });
      }
    }
  }
  watch(checked, () => {
    expandedRowKeys.value = [];
    checked.value && Object.assign(expandedRowKeys.value, recursion());
  })
  return {
    expandedRowKeys,
    onExpand
  }
}