
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    title: { type: String, default: "" },
    data: {
      type: Array,
      default: function() {
        return [];
      },
      isTitle: {
        type: Boolean,
        default: true
      }
    }
  }
});
