import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5141c30c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ver-table",
  cellspacing: "0",
  cellpadding: "0"
}
const _hoisted_2 = { class: "ver-title" }
const _hoisted_3 = { colspan: "2" }
const _hoisted_4 = { class: "left-td" }
const _hoisted_5 = { class: "rigth-td" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", _hoisted_2, [
      _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (row, i) => {
      return (_openBlock(), _createElementBlock("tr", { key: i }, [
        _createElementVNode("td", _hoisted_4, _toDisplayString(row.name), 1),
        _createElementVNode("td", _hoisted_5, _toDisplayString(row.value), 1)
      ]))
    }), 128))
  ]))
}